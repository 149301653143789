<template>
    <main>
        <page-header-compact>Configuración -  Antecedentes</page-header-compact>
        <div class="container-xl px-4 mt-n10">
            <div class="card">
                <div class="card-body">
                    <div class="col-12 d-flex justify-content-end py-4"> 
                        <button @click="modificar=false; abrirModal();" type="button" class="btn btn-primary ">
                            Crear Antecedente&nbsp;<i class="fas fa-plus"></i>
                        </button>
                    </div>
                    <div class="modal fade" id="modalAntecedente" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title" id="exampleModalLabel">{{tituloModal}}</h5>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                                <div class="modal-body">
                                    <form >
                                                                   
                                        <div class="form-group">
                                            <label class="form-label">Grupo</label>
                                            <select  v-model="antecedente.id_grupo"   class="form-control" :class="{'is-invalid':$v.antecedente.id_grupo.$error && $v.antecedente.id_grupo.$dirty}" >
                                                <option :value="item.id" v-for="item in grupos" :key="item.id">
                                                    {{ item.nombre }}
                                                </option >
                                            </select>
                                        </div>
                                        <div class="form-group">
                                            <label class="form-label">Tipo de Antecedente</label>
                                            <select  v-model="antecedente.id_tipo"    class="form-control"  :class="{'is-invalid':$v.antecedente.id_tipo.$error && $v.antecedente.id_tipo.$dirty}">
                                                <option :value="item.id" v-for="item in tipos" :key="item.id">
                                                    {{ item.nombre }}
                                                </option >
                                            </select>
                                        </div>
                                        <div class="form-group">
                                            <label class="form-label">Sexo</label>
                                            <select  v-model="antecedente.sexo"   class="form-control" :class="{'is-invalid':$v.antecedente.sexo.$error && $v.antecedente.sexo.$dirty}">
                                                <option value="M">MASCULINO</option>
                                                <option value="F">FEMENINO</option>
                                                <option value="A">AMBOS</option>
                                            </select>
                                        </div>
                                        <div class="form-group">
                                            <label class="form-label">Descripcion</label>
                                            <input type="text" class="form-control" v-model="antecedente.descripcion"  :class="{'is-invalid':$v.antecedente.descripcion.$error && $v.antecedente.descripcion.$dirty}">
                                        </div>
                                        <div class="form-group">
                                            <label class="form-label">Obligatorio</label>
                                            <select  v-model="antecedente.obligatorio"   class="form-control" :class="{'is-invalid':$v.antecedente.obligatorio.$error && $v.antecedente.obligatorio.$dirty}" >
                                                <option value="S">Si</option>
                                                <option value="N">No</option>
                                            </select>
                                        </div>
                                        <div class="form-group">
                                            <label class="form-label">Gestante</label>
                                            <select  v-model="antecedente.gestante"   class="form-control" :class="{'is-invalid':$v.antecedente.gestante.$error && $v.antecedente.gestante.$dirty}" >
                                                <option value="S">Si</option>
                                                <option value="N">No</option>
                                            </select>
                                        </div>
                                        <div class="" v-if="tipoSeleccionado==='OPC'">
                                            <div class="card-header bg-light ">Posibles opciones</div>
                                            <div class="card-body">
                                                <div class="form-group row" >
                                                    <div class="col-8">
                                                        <label class="form-label">Opciones</label>
                                                        <input type="text" class="form-control" v-model="opcion.valor" :class="{'is-invalid':$v.opcion.valor.$error && $v.opcion.valor.$dirty}" >
                                                    </div>
                                                    <div class="col-4 mt-4 py-2"> 
                                                        <button class="btn btn-primary " @click.prevent="agregarOpcion();" type="button">Agregar Opciones&nbsp;<i class="fa fa-plus"></i></button>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <table class="table table-striped table-sm ">
                                                        <tr class="table-primary">
                                                            <th>Opcion</th>
                                                            <th></th>
                                                        </tr>
                                                        <tr v-for="(item,i) in antecedente.opciones" :key="i">
                                                            <td>{{item.valor}}</td>
                                                            <td>
                                                                <button class="btn btn-outline-danger"  @click.prevent="removerOpcion()">
                                                                    <i class="fas fa-trash-alt"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="">
                                            <div class="card-header bg-light ">Edades</div>
                                            <div class="card-body">
                                                <div class="form-group row">
                                                    <div class="col-4">
                                                        <label class="form-label">Edad Inicio</label>
                                                        <input type="number" class="form-control" v-model="edad.edad_inicio" :class="{'is-invalid':$v.edad.edad_inicio.$error && $v.edad.edad_inicio.$dirty}">
                                                    </div>
                                                    <div class="col-4">
                                                        <label class="form-label">Edad Final</label>
                                                        <input type="number" class="form-control"  v-model="edad.edad_final" :class="{'is-invalid':$v.edad.edad_final.$error && $v.edad.edad_final.$dirty}">
                                                    </div>
                                                    <div class="col-4 mt-4 py-2"> 
                                                        <button class="btn btn-primary " @click.prevent="agregarEdad();" type="button">Agregar Edad&nbsp;<i class="fa fa-plus"></i></button>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <table class="table table-striped table-sm">
                                                        <tr class="table-primary">
                                                            <th>Edad Inicio</th>
                                                            <th>Edad Final</th>
                                                            <th></th>
                                                        </tr>
                                                        <tr v-for="(item,i) in antecedente.edades" :key="i">
                                                            <td>{{item.edad_inicio}}</td>
                                                            <td>{{item.edad_final}}</td>
                                                            <td>
                                                                <button class="btn btn-outline-danger"  @click.prevent="removerEdad()">
                                                                    <i class="fas fa-trash-alt"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="">
                                            <div class="card-header bg-light ">Ambitos</div>
                                            <div class="card-body">
                                                <div class="form-group row">
                                                    <div class="col-8">
                                                        <label class="form-label">Seleccione un ambito</label>
                                                        <select v-model="ambito_seleccionado" class="form-control" :class="{'is-invalid':$v.ambito_seleccionado.$error && $v.ambito_Seleccionado.$dirty}"  >
                                                            <option :value="item" v-for="item in ambitos" :key="item.id">
                                                                {{ item.desambito }}
                                                            </option >
                                                        </select>

                                                    </div>
                                                    <div class="col-4 mt-4 py-2"> 
                                                        <button class="btn btn-primary" @click.prevent="agregarAmbito();" type="button">Agregar Ambito&nbsp;<i class="fa fa-plus"></i></button>
                                                    </div>
                                                </div>
                                                <div class="form-group">
                                                    <table class="table table-striped table-sm">
                                                        <tr class="table-primary">
                                                            <th>Descripcion</th>
                                                            <th></th>
                                                        </tr>
                                                        <tr v-for="item in antecedente.ambito" :key="item.id">
                                                            <td>{{item.desambito}}</td>
                                                            <td>
                                                                <button class="btn btn-outline-danger"  @click.prevent="removerAmbito()">
                                                                    <i class="fas fa-trash-alt"></i>
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </form>
                                </div>
                                
                                <div class="modal-footer">
                                    <button class="btn btn-success" @click="guardar()">
                                        <i class="fas fa-save"></i>&nbsp;Guardar
                                     </button>
                                    <button type="button" class="btn btn-danger" data-dismiss="modal">Cerrar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="table table-responsive">
                        <table class="table table-striped">
                            <tr class="table-primary">
                                <th>Grupo</th>
                                <th>Tipo de antecedente</th>
                                <th>Sexo</th>
                                <th>Descripcion</th>
                                <th>Obligatorio</th>
                                <th>Gestante</th>
                                <th>Activo</th>
                                <th>Opciones</th>
                            </tr>
                            <tr v-for="item in antecedentes" :key="item.id">
                                <td>{{item.grupo.nombre}}</td>
                                <td>{{item.tipo_antecedente.nombre}}</td>
                                <td>{{item.sexo}}</td>
                                <td>{{item.descripcion}}</td>
                                <td>{{item.obligatorio}}</td>
                                <td>{{item.gestante}}</td>
                                <td v-if="item.activo==='S'">
                                    <h4><span class="badge badge-success"><i class="fa fa-check"></i> Activo</span></h4>
                                </td>
                                <td v-if="item.activo==='N'">
                                    <h4><span class="badge badge-danger"><i class="fa fa-ban"></i> Inactivo</span></h4>
                                </td>
                                <td>
                                   <div class="form-row">
                                        <div class="form-group px-1">
                                            <button @click="modificar=true; abrirModal(item);" class="btn btn-outline-primary lift" >
                                                <i class="fa fa-edit fa-fw"></i>Editar
                                            </button>
                                        </div>
                                        <div class="form-group px-1">
                                            <button @click="eliminarAntecedente(item.id);" class="btn btn-outline-danger lift" >
                                                <i class="fa fa-trash-alt fa-fw"></i>Eliminar
                                            </button>
                                        </div>
                                        <div class="form-group px-1">
                                            <button @click="cambiarEstado(item.id);" class="btn btn-outline-warning lift" >
                                                <i class="fa fa-sync-alt fa-fw"></i>Estado
                                            </button>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                        
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import PageHeaderCompact from "../../../../../components/layouts/content/page-header-compact";
import antecedenteService from '../../../../../services/antecedenteService';
import grupoAntecedenteService from '../../../../../services/grupoAntecedenteService';

import 'bootstrap';
import $ from 'jquery';
import Swal from "sweetalert2";
import {required} from "vuelidate/lib/validators";
import tipoAntecedenteService from '../../../../../services/tipoAntecedenteService';
import ambitoService from '../../../../../services/ambitoService';
import edadAntecedenteService from '../../../../../services/edadAntecedenteService';
import opcionAntecedenteService from '../../../../../services/opcionAntecedenteService';
import ambitoAntecedenteService from '../../../../../services/ambitoAntecedenteService';



export default {
    components: {PageHeaderCompact},
    data(){
        return{
            ambito_seleccionado:'',
            antecedente:{
                id:'',
                id_grupo:'',
                id_tipo:'',
                sexo:'',
                descripcion:'',
                obligatorio:'',
                edades:[],
                opciones:[],
                ambito:[],
                gestante:''
            },
            edad:{
                edad_inicio:'',
                edad_final:'',
            },
            opcion:{
                valor:'',
            },
            id:0,
            modificar:true,
            tituloModal:'',
            antecedentes:{},
            grupos:{},
            tipos:[],
            ambitos:{},
        }
    },
    validations (){
        return {
            antecedente:{
                id_grupo:{required},
                id_tipo:{required},
                sexo:{required},
                descripcion:{required},
                obligatorio:{required},
                gestante:{required}
            },
            edad:{
                edad_inicio:{required},
                edad_final:{required},
            },
            ambito_seleccionado:{required},
            opcion:{
                valor:{required},
            }
        }
    },
    methods: {
        async abrirModal(datos={}){
            $('#modalAntecedente').modal('show');
            if(this.modificar){
                this.antecedente.id=datos.id;
                this.tituloModal="Modificar Antecedente";
                this.antecedente.id_grupo=datos.id_grupo;
                this.antecedente.id_tipo=datos.id_tipo;
                this.antecedente.sexo=datos.sexo;
                this.antecedente.descripcion=datos.descripcion;
                this.antecedente.obligatorio=datos.obligatorio;
                this.antecedente.gestante=datos.gestante;
                const response=await edadAntecedenteService.showByAc(this.antecedente.id);
                this.antecedente.edades = response.data;
                const res = await ambitoAntecedenteService.showByAc(this.antecedente.id);
                this.antecedente.ambito = res.data.map(x=>{
                    return{
                        idambito:x.ambito.idambito,
                        desambito:x.ambito.desambito,
                    }
                });
                if(this.tipoSeleccionado==='OPC'){
                    const response=await opcionAntecedenteService.showByAc(this.antecedente.id);
                    this.antecedente.opciones = response.data;
                }
            }else{
                this.id=0;
                this.tituloModal="Crear Antecedente";
                this.antecedente.id_grupo="";
                this.antecedente.id_tipo="";
                this.antecedente.sexo="";
                this.antecedente.obligatorio="";
                this.antecedente.gestante="";
                this.antecedente.descripcion="";
                this.antecedente.edades=[];
                this.antecedente.ambito=[];
                this.antecedente.opciones=[];
            }
        },
        async cargarAntecedentes(){
            const response = await antecedenteService.index();
            this.antecedentes=response.data;
        },
        async cargarGrupos(){
            const response = await grupoAntecedenteService.index();
            this.grupos=response.data;
        },
        async cargarTipoAntecedente(){
            const response = await tipoAntecedenteService.index();
            this.tipos=response.data;
        },
        async cargarAmbitos(){
            const response = await ambitoService.list();
            this.ambitos=response.data;
        },
        async guardar(){ 
            this.$v.antecedente.$touch();
            if(this.$v.antecedente.$invalid){
                return 
            }
            if(this.modificar){
                this.LoaderSpinnerShow();
                await antecedenteService.update(this.antecedente);
                this.LoaderSpinnerHide();
                Swal.fire('Datos actualizados con exito','', 'success');
                this.limpiarCampos();
                $('#modalAntecedente').modal('hide');
                this.cargarAntecedentes();
                
            }else{
                this.LoaderSpinnerShow();
                await antecedenteService.store(this.antecedente);
                this.LoaderSpinnerHide();
                Swal.fire('Datos guardados con exito','', 'success');
                this.limpiarCampos();
                $('#modalAntecedente').modal('hide');
                this.cargarAntecedentes();
            }
            
        },
        async eliminarAntecedente(id){
            this.LoaderSpinnerShow();
            await antecedenteService.delete(id);
            this.LoaderSpinnerHide();
            Swal.fire('Datos eliminados con exito','', 'success');
            this.limpiarCampos();
            $('#modalAntecedente').modal('hide');
            this.cargarAntecedentes();
        },
        async cambiarEstado(id){
            this.LoaderSpinnerShow();
            await antecedenteService.estado(id);
            this.LoaderSpinnerHide();
            Swal.fire('Estado actualizado con exito','', 'success');
            this.cargarAntecedentes();
        },
        agregarEdad(){
            this.$v.edad.$touch();
            if (this.$v.edad.$invalid) {
                return;
            }
            this.antecedente.edades.push({...this.edad});
            this.edad={};
        },
        agregarOpcion(){
            this.$v.opcion.$touch();
            if (this.$v.opcion.$invalid) {
                return;
            }
            this.antecedente.opciones.push({...this.opcion});
            this.opcion={};
        },
        agregarAmbito(){
            this.antecedente.ambito.push({...this.ambito_seleccionado});
        },
        removerOpcion(index) {
            this.antecedente.opciones.splice(index, 1);
        },
        removerEdad(index) {
            this.antecedente.edades.splice(index, 1);
        },
        removerAmbito(index) {
            this.antecedente.ambito.splice(index, 1);
        },
        limpiarCampos(){
            this.edad={},
            this.opcion={}
        },
    },
    created(){
        this.cargarAntecedentes();
        this.cargarGrupos();
        this.cargarTipoAntecedente();
        this.cargarAmbitos();
    },
    computed:{
        tipoSeleccionado(){
            const tipo= this.tipos.find(x=>x.id===this.antecedente.id_tipo);
            return tipo===undefined?'':tipo.codigo;
        }
    }
}
</script>

